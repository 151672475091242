#chatLog {
  border: 1px solid #ccc;
  height: 500px;
  margin-bottom: 10px;
  padding: 10px;
  overflow-y: scroll;
}

.message {
  white-space: pre-line;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.user {
  text-align: right;
  background-color: #e6f7ff;
}

.chatbot {
  background-color: #d9d9d9;
}

.loader {
  background: linear-gradient(-90deg, #f0f0f0 50%, #d9d9d9 50%) 0 0 / 200% 100%;
  border-radius: 10px;
  width: 50%;
  height: 10px;
  animation: 3s infinite loading;
  display: inline-block;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.hidden {
  display: none;
}

/*# sourceMappingURL=index.ee80146a.css.map */
